import React, { Component } from 'react';
import Logo from 'vectors/logos/karsnes.js';
import Newsletter from 'components/Newsletter/Newsletter';
import FacebookIcon from 'vectors/icons/facebook-white.js';
import InstagramIcon from 'vectors/icons/instagram-white.js';
import 'components/Footer/Footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer-nl turkish">
          <div className="footer-nl-inner">
            <h1>Fylgstu með</h1>
            <p>og skráðu þig á póstlistann</p>
            <Newsletter />
          </div>
        </div>
        <div className="footer cf">
          <div className="ui column grid centered stackable container">
            <div className="four wide column">
              <div className="footer-logo">
                <Logo />
              </div>
            </div>
            <div className="five wide column">
              <div className="soc-icons cf">
                <a href="https://www.facebook.com/karsnesid/" target="_blank" className="cf">
                  <FacebookIcon /><span>Facebook</span>
                </a>
                <a href="https://instagram.com/karsnesid" target="_blank" className="cf">
                  <InstagramIcon /><span>Instagram</span>
                </a>
              </div>
            </div>
            <div className="eight wide column footer-notice">
              <p>Allt myndefni birt með fyrirvara - byggingarnefndarteikningar gilda.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;

import React from "react";

const SvgInstagramWhite = props => (
  <svg viewBox="0 0 544.78 544.78" {...props}>
    <path
      d="M394.44 0h-244.1C67.44 0 0 67.44 0 150.34v244.1c0 82.9 67.44 150.34 150.34 150.34h244.1c82.9 0 150.34-67.44 150.34-150.34v-244.1C544.78 67.44 477.34 0 394.44 0zm102 394.44a102.12 102.12 0 0 1-102 102h-244.1a102.1 102.1 0 0 1-102-102v-244.1a102.11 102.11 0 0 1 102-102h244.1a102.12 102.12 0 0 1 102 102v244.1zM272.39 132C195 132 132 195 132 272.4s63 140.37 140.38 140.37 140.38-63 140.38-140.37S349.79 132 272.39 132zm0 232.41a92 92 0 1 1 92-92 92.15 92.15 0 0 1-92 92.02zM418.65 91a35.42 35.42 0 1 0 25.07 10.37A35.58 35.58 0 0 0 418.65 91z"
      fill="#fff"
    />
  </svg>
);

export default SvgInstagramWhite;


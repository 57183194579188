import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'vectors/logos/karsnes.js';
import { stack as Menu } from 'react-burger-menu';
import HashLink from './HashLink.js';

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { setNavigationState } from 'actions/navigationActions';

import 'semantic/dist/components/menu.min.css';
import './Nav.scss';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
  }

  toggleMenu() {
    this.props.setNavigationState(this.props.navigation.isOpen ? false : true);
  }

  onStateChange(state) {
    this.props.setNavigationState(state.isOpen);
  }

  render() {
    const links = [];

    this.props.links.map(function(item) {
      var link = null;

      if(item.href.substr(0,1) === '#') {
        link = <HashLink key={ item.href } hash={ item.href } onClick={ this.toggleMenu }>{ item.title }</HashLink>;
      } else if(item.href.indexOf('.pdf') !== -1) {
        link = (
          <a href={ item.href } target="_blank" rel="noopener noreferrer">
            { item.title }
          </a>
        )
      } else {
        link = <Link key={ item.href } to={ item.href } onClick={ this.toggleMenu }>{ item.title }</Link>;
      }

      links.push(link);
    }.bind(this));

    return (
      // <HashLink hash="#ibudir" onClick={ this.toggleMenu }>Íbúðir</HashLink>
      <React.Fragment>
        <Menu pageWrapId={ 'app' } onStateChange={ this.onStateChange } isOpen={ this.props.navigation.isOpen } customBurgerIcon={ false } customCrossIcon={ false } right>
          { links.map( (link) => link ) }
        </Menu>

        <header className="header-fixed">
          <div className="ui container cf">
            <Link to='/'>
              <Logo />
            </Link>

            <button aria-label="Valmynd" className={ this.props.navigation.isOpen ? 'hamburger--collapse is-active hamburger right' : 'hamburger right'} type="button" onClick={this.toggleMenu}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
              <span className="hamburger-label">Valmynd</span>
            </button>
          </div>
        </header>
      </React.Fragment>
    );
  }
}


Nav.propTypes = {
  navigation: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  navigation: state.navigation
});

export default connect(mapStateToProps, {
  setNavigationState
})(Nav);
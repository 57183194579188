// const serviceWorker = require('./serviceWorker.js');

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Router, Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { spring, AnimatedSwitch } from 'react-router-transition';
import CookieConsent from 'react-cookie-consent';
import { CookiesProvider, useCookies } from 'react-cookie';
import queryString from 'query-string';
import PropertyListings from 'components/Property/Listings.js';
import PropertySearch from 'components/Property/Search.js';
import smoothscroll from 'smoothscroll-polyfill';
import { calculateResponsiveState } from 'redux-responsive';

import { Provider } from 'react-redux';
import store from './store';

import 'semantic/dist/components/container.min.css';
import 'semantic/dist/components/grid.min.css';
import 'index.scss';

const App = props => {
  const query = queryString.parse(window.location.search);
  const [ cookies, setCookie, removeCookie ] = useCookies(['ref']);
  if('ref' in query && query.ref) {
    const soluadilar = ['miklaborg', 'lind', 'domusnova', 'remax'];
    if(soluadilar.indexOf(query.ref) !== -1) {
      setCookie('ref', query.ref, {
        maxAge: 604800,
        path: '/'
      })
    } else if(query.ref == 'reset') {
      removeCookie('ref', { path: '/' });
    }

  }

  return (
    <React.Fragment>
      { props.children }
    </React.Fragment>
  )
}

const history = createHistory();

if(process.env.NODE_ENV === 'production') {
  /*
  ReactPixel.init('242558449742647', {}, {
    autoConfig: true
  });
  */

  // ReactPixel.pageView();
  /*
  ReactGA.initialize('G-6GWY70H5CQ');
  ReactGA.pageview(window.location.pathname + window.location.hash + window.location.search);
  */

  history.listen(function(location) {
    // ReactPixel.pageView();
    // ReactGA.pageview(location.pathname + location.hash + location.search);
  });
}


// kick off the polyfill!
smoothscroll.polyfill();

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 500,
    damping: 100,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 0,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.4),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const opacityTransition = {
	atEnter: {
		opacity: 0,
//		scale: 0.95
	},
	atLeave: {
		opacity: -1,
//		scale: 0.9
	},
	atActive: {
		opacity: 1,
//		scale: 1
	}
}
const props = {
  atEnter: opacityTransition.atEnter,
  atLeave: opacityTransition.atLeave,
  atActive: opacityTransition.atActive,
  className: 'transition-wrap',
  mapStyles: mapStyles
};

ReactDOM.render(
  <React.Fragment>
    <CookieConsent
      location="bottom"
      buttonText="Samþykkja X"
      style={{ background: "rgba(242, 242, 242, 0.85)", color: "#444", fontSize: "16px" }}
      buttonStyle={{ background: "transparent", color: "#367384", fontSize: "15px", cursor: "pointer", padding: "0" }}
      containerClasses="footer-cc"
      expires={ 150 }
    >
      <strong>Hafnarbraut14.is notar vefkökur</strong> til að auðvelda þér að vafra um vefinn.
    </CookieConsent>
    <CookiesProvider>
      <App>
        <Provider store={ store }>
          <Router history={ history }>
            <AnimatedSwitch {...props}>
              <Route exact path='/' component={ PropertyListings }/>
              <Route path='/ibudir/leit' component={ PropertySearch } />
              <Route exact path='/hafnarbraut14/:id(\d+)?' component={ PropertyListings }/>
              <Route path="*">
                <h1>Síða fannst ekki</h1>
              </Route>
            </AnimatedSwitch>
          </Router>
        </Provider>
      </App>
    </CookiesProvider>
  </React.Fragment>
, document.getElementById('app'));

// <Route path='/:hus(hafnarbraut9|hafnarbraut11)/:id(\d+)' component={ Property } />

// calculate the initial state
store.dispatch(calculateResponsiveState(window));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


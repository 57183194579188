import React, { Component } from 'react';
// import Dropdown from 'react-dropdown';
import ReactDOM from "react-dom";
import { Dropdown } from 'semantic-ui-react';
import { Link } from "react-router-dom";
// import queryString from 'query-string'
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import IntroImage from 'components/IntroImage/IntroImage';
import ExpandIcon from 'vectors/icons/expand.js';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga'

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchApartments } from 'actions/propertyActions';

import 'semantic/dist/components/dropdown.min.css';
import 'react-dropdown/style.css';
import './Search.scss';

class PropertySearchItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      sqm: this.props.sqm,
      floor: this.props.floor,
      rooms: this.props.rooms,
      property: this.props.property,
      priceRange: this.props.priceRange
    };

    this.filterItems = this.filterItems.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.state = {};
  }

  filterItems(data) {
    var sqm = this.state.sqm.split('-');
    var priceRange = this.state.priceRange.split('-');
    var herb = this.state.rooms;
    if(typeof herb === 'string') {
      var herb = herb.indexOf('-') !== -1 ? herb.split('-') : herb;
    }

    herb = typeof herb === 'object' ? [parseInt(herb[0], 10), parseInt(herb[1], 10)] : herb;

    var filters = {
      'property': this.state.property,
      'floor': this.state.floor,
      'herb': herb,
      'birt_staerd': [sqm[0], sqm[1]],
      'verd': [priceRange[0], priceRange[1]]
    };

    console.log({
      filters,
      data
    })

    const result = [...Object.values(data)]

    return result.filter(function(item) {
      var matches = 0;

      /*
      if(item.on_sale === "0") {
        return false;
      }
      */

      for(var filterName in filters) {
        var filter = filters[filterName];

        if(filterName === 'property') {
          var matchFound = (filter === 'all' || filter === item.hus) ? true : false;

          if(matchFound) {
            matches++;
            continue;
          } else {
            continue;
          }
        }

        var min = typeof filter === 'object' ? filter[0] : filter;
        var max = typeof filter === 'object' ? filter[1] : filter;

        var floor = item.floor;

        var current = item[filterName];

        if(filterName == 'herb') {
          current = current.substr(0, 1);
        }

        // Price Range
        if(filterName === 'verd' && filter[0] !== 'all') {
          min = parseInt(min, 10) * 1000000;
          max = parseInt(max, 10) * 1000000;
        }

        if(filterName === 'floor' && min === 'all') {
          min = 1;
          max = 5;
        }

        if(filterName === 'verd' && min === 'all') {
          min = 0;
          max = 999999999;
        }

        if(filterName === 'verd' && current === null) {
          current = 0;
        }

        min = parseInt(min, 10);
        max = parseInt(max, 10);

        if(current >= min && current <= max) {
          matches++;
        }
      }

      return matches === 5 ? true : false;
    });
  }

  componentWillReceiveProps(nextProps) {
    var state = {};

    for(var propName in nextProps) {
      if(nextProps[propName] !== this.state[propName]) {
        state[propName] = nextProps[propName];
      }
    }

    if(state) {
      this.setState(state);
    }
  }

  renderItems(data) {
    var apartments = this.filterItems(data);

    const apartmentItems = apartments.map((apt) => <tr key={ apt.hus + '-' + apt.ibudarnumer }>
      <td>{ apt.floor }. hæð</td>
      <td>{ apt.hus_formatted }</td>
      <td>{ apt.title_short }</td>
      <td>{ apt.herb }</td>
      <td>{ apt.birt_staerd } m<sup>2</sup></td>
      <td className={ apt.sold === "0" ? '' : 'td-sold' }>{ apt.formatted_verd }</td>
      <td>
        <Link to={ '/' + apt.hus + '/' + apt.ibudarnumer }>
          <ExpandIcon />
        </Link>
      </td>
    </tr>);

    return apartments.length > 0 ? apartmentItems : this.props.noResults;
  }

  render() {
    if(!this.state.data) {
      return <tr><td colSpan="7">Loading...</td></tr>;
    }

    var tr = this.renderItems(this.state.data);

    return (
      <React.Fragment>
        { tr }
      </React.Fragment>
    );
  }
}

class PropertySearch extends Component {
  constructor(props) {
    super(props);

    this.noResults = <tr><td colSpan="7">Engar íbúðir fundust.</td></tr>;
    this.initFilters = this.initFilters.bind(this);
    this.state = {
      apartmentList: this.noResults,
      floor: 'all',
      rooms: '1-5',
      sqm: '20-190',
      priceRange: 'all',
      property: 'all'
    };

    this.myRef = React.createRef();
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.fetchApartments();
    if(this.unlisten) {
      this.unlisten();
    }

    this.unlisten = this.props.history.listen((location, action) => {
      this.initFilters(location);
    });

    this.initFilters(this.props.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  initFilters(location) {
    const querySearch = require('stringquery');

    this.query = querySearch(location.search);

    var state = {};
    var queries = ['floor', 'rooms', 'sqm', 'priceRange', 'property'];

    for(var i = 0; i < queries.length; i++) {
      var queryName = queries[i];
      if(queryName in this.query) {
        state[queryName] = this.query[queryName];
      }
    }

    if(state) {
      this.setState(state);
    }
  }

  onFormChange(inputName, e, input) {
    const filters = {
      floor: this.state.floor,
      rooms: this.state.rooms,
      sqm: this.state.sqm,
      priceRange: this.state.priceRange,
      property: this.state.property
    }

    var history = null;

    filters[inputName] = input.value;

    const search = Object.keys(filters).map(function(x) {
      return x + '=' + filters[x];
    }).join('&');

    if(search) {
      history = {
        'search': '?' + search
      };
    };

    if(history) {
      this.props.history.push(history);
    }
  }

  render() {
    console.log(
      `props:`, this.props
    )
    const floors = [
      { text: 'Allar hæðir', value: 'all' },
      { text: '1. hæð', value: '1' },
      { text: '2. hæð', value: '2' },
      { text: '3. hæð', value: '3' },
      { text: '4. hæð', value: '4' },
      { text: '5. hæð', value: '5' }
    ];
    const rooms = [
      { text: 'Allar íbúðir', value: '1-5' },
      { text: 'Stúdíó', value: '1' },
      { text: '2 herb.', value: '2' },
      { text: '3 herb.', value: '3' },
      { text: '4 herb.', value: '4' },
      { text: '5 herb.', value: '5' }
    ];

    const sqmRange = [
      { text: 'Íbúðarstærð', value: '20-190' },
      { text: '20 - 70 m²', value: '20-70' },
      { text: '70 - 90 m²', value: '70-90' },
      { text: '90 - 110 m²', value: '90-110' },
      { text: '110 - 130 m²', value: '110-130' },
      { text: '130 - 190 m²', value: '130-190' }
    ];

    const priceRange = [
      { text: 'Verðbil', value: 'all' },
      { text: '30 - 40 mkr', value: '30-40' },
      { text: '40 - 50 mkr', value: '40-50' },
      { text: '50 - 60 mkr', value: '50-60' },
      { text: '60 - 70 mkr', value: '60-70' },
      { text: '70 - 80 mkr', value: '70-80' },
      { text: '80 - 90 mkr', value: '80-90' }
    ];

    const properties = [
      { text: 'Öll hús', value: 'all' },
      /*
      { text: 'Hafnarbraut 9', value: 'hafnarbraut9' },
      { text: 'Hafnarbraut 11', value: 'hafnarbraut11' },
      { text: 'Hafnarbraut 12', value: 'hafnarbraut12' },
      { text: 'Hafnarbraut 13 - 15', value: 'hafnarbraut13-15' },
      */
      { text: 'Hafnarbraut 14', value: 'hafnarbraut14' }
    ];

    const currentProperty = properties.filter(function(item) {
      if(item.value === this.state.property) {
        return item;
      }
    }.bind(this));

    const currentFloor = floors.filter(function(item) {
      if(item.value === this.state.floor) {
        return item;
      }
    }.bind(this));

    const currentRoom = rooms.filter(function(item) {
      if(item.value === this.state.rooms) {
        return item;
      }
    }.bind(this));

    const currentSqmRange = sqmRange.filter(function(item) {
      if(item.value === this.state.sqm) {
        return item;
      }
    }.bind(this));

    const currentPriceRange = priceRange.filter(function(item) {
      if(item.value === this.state.priceRange) {
        return item;
      }
    }.bind(this));

    const links = [
      { href: '/', title: 'Forsíða' },
      { href: '/', title: 'Hafnarbraut 14' },
      { href: '/#/eignir', title: 'Íbúðir' },
      { href: '/#/thjonusta', title: 'Þjónusta' },
      { href: '/#/umhverfi', title: 'Umhverfi' },
      { href: '/#/svaedid', title: 'Svæðið' },
      { href: '/#/postlisti', title: 'Póstlisti' },
    ];

    return (
      <React.Fragment>
        <Nav links={ links }/>
        <div className="property-search" ref={ (node) => this.myRef = node }>
          <div className="property-search-filters">
            <div className="ui container">
              <div className="ui five column row centered stackable doubling grid">
                <div className="column">
                  <Dropdown fluid options={ properties } onChange={ this.onFormChange.bind(this, 'property') } text={ currentProperty[0].text } defaultValue={ currentProperty[0].value } placeholder="Hús" />
                </div>
                <div className="column">
                  <Dropdown fluid options={ floors } onChange={ this.onFormChange.bind(this, 'floor') } text={ currentFloor[0].text } defaultValue={ currentFloor[0].value } placeholder="Hæð" />
                </div>
                <div className="column">
                  <Dropdown fluid options={ rooms } onChange={ this.onFormChange.bind(this, 'rooms') } text={ currentRoom[0].text } defaultValue={ currentRoom[0].value } placeholder="Herbergjafjöldi" />
                </div>
                <div className="column">
                  <Dropdown fluid options={ sqmRange } onChange={ this.onFormChange.bind(this, 'sqm') } text={ currentSqmRange[0].text } defaultValue={ currentSqmRange[0].value } placeholder="Fermetrar" />
                </div>
                <div className="column">
                  <Dropdown fluid options={ priceRange } onChange={ this.onFormChange.bind(this, 'priceRange') } text={ currentPriceRange[0].text } defaultValue={ currentPriceRange[0].value } placeholder="Verðbil" />
                </div>
              </div>
            </div>
          </div>

          <div className="property-search-results ui container">
            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Hæð</th>
                  <th>Hús</th>
                  <th>Íbúð</th>
                  <th>Herb</th>
                  <th>Alls, m<sup>2</sup></th>
                  <th>Verð</th>
                  <th>Skoða</th>
                </tr>
              </thead>
              <tbody>
                <PropertySearchItem noResults={ this.noResults }
                          data={ this.props.apartmentList }
                          floor={ this.state.floor }
                          rooms={ this.state.rooms }
                          sqm={ this.state.sqm }
                          priceRange={ this.state.priceRange }
                          property={ this.state.property }
                />
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

PropertySearch.propTypes = {
  fetchApartments: PropTypes.func.isRequired,
  apartmentList: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  apartmentList: state.property.items
});

export default connect(mapStateToProps, {
  fetchApartments
})(PropertySearch);
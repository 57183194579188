import React from 'react';
import './Explanation.scss';

const Explanation = ({ property }) => (
  <ul className="property-list-explanation">
    <li key="ibud_laus" className="property-list-explanation-available">
      <span className="property-list-explanation-circle">&nbsp;</span>
      Laus
    </li>
    { (property !== 'hafnarbraut13-15' && property !== 'hafnarbraut14') && 
    <li key="ibud_til_solu_sidar" className="property-list-explanation-later">
      <span className="property-list-explanation-circle">&nbsp;</span>
        Til sölu síðar
      </li>  
    }
    {
      property === 'hafnarbraut14' && (
        <li key="ibud_fratekin" className="property-list-explanation-taken">
          <span className="property-list-explanation-circle">&nbsp;</span>
          Frátekin
        </li>  
      )
    }
    <li key="ibud_seld" className="property-list-explanation-sold">
      <span className="property-list-explanation-circle">&nbsp;</span>
      Seld
    </li>
  </ul>
)

export default Explanation;
import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

import './Area.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PropertyArea = ({ createRef }) => {
  const images = [
      require('images/karsnes/karsnes-solsetur-2018-11-30.jpg'),
      require('images/stemningsmyndir/AY4I6870_eds.jpg'),
      require('images/stemningsmyndir/AY4I6874_eds.jpg'),
      require('images/stemningsmyndir/AY4I6887_eds.jpg'),
      require('images/stemningsmyndir/AY4I6915_eds.jpg'),
      require('images/stemningsmyndir/AY4I6923_eds.jpg'),
      require('images/stemningsmyndir/AY4I6926_eds.jpg'),
      require('images/karsnes/DSC00022.jpg'),
      require('images/karsnes/Karsnes-2810.jpg'),
      require('images/karsnes/Karsnes-2813.jpg'),
      require('images/karsnes/Karsnes-2821.jpg'),
      require('images/karsnes/Karsnes-2826-helgmngur.jpg'),
      require('images/karsnes/Karsnes-2832.jpg'),
      require('images/karsnes/Karsnes-2834.jpg'),
      require('images/karsnes/Karsnes-2844.jpg'),
      require('images/karsnes/karsnesdron-3144.jpg')
    ];

    const thjonustaPoster = require('images/videos/thjonusta-n.jpg');
    const honnudirPoster = require('images/videos/honnudir.jpg');
    const bruinPoster = require('images/videos/bruin.jpg');
    const umhverfiPoster = require('images/videos/umhverfi-n.jpg');
    const sizes = ['(min-width: 1024px) 576px, (min-width: 1366px) 768px, (min-width: 1440px) 810px, 400px'];

    return (
      <React.Fragment>
        <div className="ui grid stackable property-area-video" data-ref="thjonusta" ref={(node) => { createRef(node) }}>
          <div className="row">
            <div className="six wide tablet four wide computer column turkish property-area-text">
              <h1>Þjónusta</h1>
              <p>Skólar og leikskólar eru í hverfinu ásamt opnum leiksvæðum. Stutt er í helstu verslanir og þjónustu sem og fjölbreytta afþreyingu. Falleg útivistarsvæði innan seilingar.</p>
            </div>
            <div className="ten wide tablet twelve wide computer column property-video-inner">
              <div className="video-fluid">
                <video poster={ thjonustaPoster } controls>
                  <source src="/videos/thjonusta.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>

        <div className="ui grid stackable property-area-video" data-ref="umhverfi" ref={(node) => { createRef(node) }}>
          <div className="row">
            <div className="ten wide tablet twelve wide computer column property-video-inner">
              <div className="video-fluid">
                <video className="video" poster={ umhverfiPoster } controls>
                  <source src="/videos/umhverfi.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="six wide tablet four wide computer column turkish property-area-text">
              <h1>Umhverfið</h1>
              <p>Heillandi umhverfi við sjávarsíðuna sem skartar fallegri náttúru og fjölbreyttum afþreyingarmöguleikum. Leik- og útivistarsvæði fyrir alla fjölskylduna.</p>
            </div>
          </div>
        </div>

        <div className="property-area" data-ref="svaedid" ref={(node) => { createRef(node) }}>
          <div className="ui grid stackable">
            <div className="row">
              <div className="seven wide column turkish property-area-text">
                <h1>Svæðið</h1>
                <p>Allt er til staðar í hverfinu: Spennandi hjóla-, hlaupa- og göngustígar við sjávarsíðuna, góðar samgönguleiðir auk þess sem ný brú frá Kársnesi yfir í miðbæ Reykjavíkur er fyrirhuguð. Grunnskóli og leikskólar eru í göngufjarlægð. Svæðið í heild mun einkennast af nýjum íbúðum í bland við atvinnubyggð. Bryggjusvæðið verður endurnýjað og gagnger uppbygging mun eiga sér stað í hverfinu sem er staðsett á einum fallegasta og gróðursælasta útsýnisstað á höfuðborgarsvæðinu.</p>
              </div>
              <div className="nine wide column property-area-gallery">
                <Carousel infiniteLoop={ true } showIndicators={ false } dynamicHeight={ true }>
                  { images.map(image =>
                    <img key={ image.src } src={ image.src } srcSet={ image.srcSet } sizes={ sizes } />
                  )}
                </Carousel>
              </div>
            </div>
          </div>

          <div className="ui grid stackable">
            <div className="row">
              <div className="eight wide column property-video-half">
                <h2>Hönnuðir</h2>
                <div className="video-fluid">
                  <video className="video" poster={ honnudirPoster } controls>
                    <source src="/videos/honnudir.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="eight wide column property-video-half">
                <h2>Brúin</h2>
                <div className="video-fluid">
                  <video className="video" poster={ bruinPoster } controls>
                    <source src="/videos/bruin.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default PropertyArea;

import {
  FETCH_APARTMENTS,
  FETCH_PROPERTIES,
  SET_FLOOR,
  LIST_HOVER,
  OPEN_POPUP,
  CLOSE_POPUP,
  CHANGE_VECTOR,
  RESET_PROPERTY_STATE,
  SET_ACTIVE_FLOOR
} from './types';

export const fetchApartments = () => dispatch => {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://karsnes.is/' : '/';

  fetch(baseUrl + 'api/apartments.json?110321')
    .then(res => res.json())
    .then(data => dispatch({
      type: FETCH_APARTMENTS,
      payload: data
    }));
}

export const fetchProperties = () => dispatch => {
  fetch('/api/properties.json?110321n')
    .then(res => res.json())
    .then(data => dispatch({
      type: FETCH_PROPERTIES,
      payload: data
    }));
}

export const setActiveFloor = floor => dispatch => {
  dispatch({
    type: SET_ACTIVE_FLOOR,
    payload: floor
  })
}

export const setCurrentFloor = floor => dispatch => {
    dispatch({
      type: SET_FLOOR,
      payload: floor
    });
}

export const onHover = item => dispatch => {
  dispatch({
    type: LIST_HOVER,
    payload: item.type === 'mouseover' ? item.target.getAttribute('data-key') : null
  });
}

export const changeVector = item => dispatch => {
  if(item.target.getAttribute('data-side') !== 'any') {
    dispatch({
      type: CHANGE_VECTOR,
      payload: item.target.getAttribute('data-side')
    });
  }

  dispatch({
    type: OPEN_POPUP,
    payload: null
  });
}

export const resetPropertyState = () => dispatch => {
  dispatch({
    type: RESET_PROPERTY_STATE,
    payload: null
  });
}

export const closePopup = item => dispatch => {
  dispatch({
    type: CLOSE_POPUP,
    payload: null
  });
}

export const openPopup = item => dispatch => {
  if(item.type === 'mouseleave') {
    dispatch({
      type: CLOSE_POPUP,
      payload: null
    })
  } else {
    const side = item.target.getAttribute('data-side');
    const apt = item.target.getAttribute('data-key');
    var timeout = 0;
  
    if(side && side !== 'any') {
      dispatch({
        type: CHANGE_VECTOR,
        payload: side
      });
  
      dispatch({
        type: OPEN_POPUP,
        payload: null
      });
  
      timeout = 200;
    }
  
    setTimeout( () =>
      dispatch({
        type: OPEN_POPUP,
        payload: apt
      }), timeout);
  }
}
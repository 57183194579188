import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, lighten, calc } from 'polished';

// ----------------------------
// Default Theme
// ----------------------------

const theme = {
  color: {
    available: darken(0.1, '#17b890'),
    sold: '#f74459',
    later: '#f2af29',
    hold: '#444'
  },
  text: {
    font: 'Kanit, sans-serif',
    fill: '#fff',
    size: '24px',
    weight: '700'
  },
  list: {
    font: 'Kanit, sans-serif',
    color: '#367384',
    border: '#367384'
  }
}

const defaultProps = { theme: theme };

// ----------------------------
// Anchor - AVAILABLE
// ----------------------------

export const AvailableAnchor = styled(Link)`
  path {
    fill: ${ props => props.theme.color.available };
    stroke-width: 1px;
    stroke: #fff;
  }

  path[data-type="text"] {
    fill: #fff;
  }

  &:hover,
  &.active {
    path {
      fill: ${ props => darken(0.2, props.theme.color.available) };
    }

    path[data-type="text"],
    path + text {
      fill: ${ props => lighten(0.4, props.theme.color.available) };
    }
  }
`

AvailableAnchor.defaultProps = defaultProps;

// ----------------------------
// Anchor - SOLD
// ----------------------------

export const SoldAnchor = styled(Link)`
  path {
    fill: ${ props => props.theme.color.sold };
  }

  &:hover,
  &:active,
  &.active {
    path {
      fill: ${ props => darken(0.2, props.theme.color.sold) };
    }

    path.text {
      fill: ${ props => darken(0.4, props.theme.color.available) };
    }
  }

  path[data-type='text'] {
    fill: #fff;

    &:hover,
    &:active,
    &.active {
      fill: #fff;
    }
  }
`

SoldAnchor.defaultProps = defaultProps;


// ----------------------------
// Anchor - LATER
// ----------------------------

export const LaterAnchor = styled(Link)`
  path {
    fill: ${ props => props.theme.color.later };
    stroke: #fff;
    stroke-width: 2px;
  }

  &:hover,
  &:active,
  &.active {
    path {
      fill: ${ props => darken(0.2, props.theme.color.later) };
    }

    path.text {
      fill: #fff;
    }
  }

  path[data-type='text'] {
    fill: #fff;

    &:hover,
    &:active,
    &.active {
      fill: #fff;
    }
  }
`

LaterAnchor.defaultProps = defaultProps;


// ----------------------------
// Anchor - HOLD
// ----------------------------

export const HoldAnchor = styled(Link)`
  path {
    fill: ${ props => props.theme.color.hold };
    stroke: #fff;
    stroke-width: 2px;
  }

  &:hover,
  &:active,
  &.active {
    path {
      fill: ${ props => darken(0.2, props.theme.color.hold) };
    }

    path.text {
      fill: #fff;
    }
  }

  path[data-type='text'] {
    fill: #fff;

    &:hover,
    &:active,
    &.active {
      fill: #fff;
    }
  }
`

HoldAnchor.defaultProps = defaultProps;

// ----------------------------
// Text
// ----------------------------

export const Text = styled.text`
  font-family: ${ props => props.theme.text.font };
  fill: ${ props => props.theme.text.fill };
  font-size: ${ props => props.theme.text.size };
  font-weight: ${ props => props.theme.text.weight };
`

Text.defaultProps = defaultProps;

// ----------------------------
// List
// ----------------------------

export const List = styled.ul`
  list-style: none;
  padding: 0;
  // width: calc(32px * 4);
  width: ${props => props.listWidth}px;
  margin: 10px auto 25px auto;
`

List.defaultProps = defaultProps;


// ----------------------------
// Floor Selection Headline
// ----------------------------
export const Headline = styled.h3`
  margin: 0;
  padding: 0;
  text-align: center;
  color: #367384;
`


// ----------------------------
// List Item
// ----------------------------

export const ListItem = styled.li`
  display: inline-block;
  padding: 0;
  font-family: ${ props => props.theme.list.font };
  text-align: center;
  cursor: pointer;

  span {
    display: block;
    width: 28px;
    height: 28px;
    line-height: 27px;  
    border: 1px solid ${ props => props.theme.list.border };
    color: ${ props => props.theme.list.color };
    margin: 2px;
  }

  &.active,
  &:hover {
    span {
      background: ${ props => props.theme.list.color };
      color: #fff;
      border-color: ${ props => darken(0.30, props.theme.list.color) };
    }
  }
`

ListItem.defaultProps = defaultProps;

// ----------------------------
// SVG
// ----------------------------

export const StyledSvg = styled.svg`
  text-align: center;
`

StyledSvg.defaultProps = defaultProps;

// ----------------------------
// Floor Group
// ----------------------------

export const FloorGroup = styled.g`
  path {
    fill: #fff;
    stroke: ${ props => darken(0.2, props.theme.color.available) };
    stroke-width: 5px;
    cursor: pointer;
  }

  path:hover,
  &.active path {
    fill: ${ props => props.theme.color.available };
  }
`

FloorGroup.defaultProps = defaultProps;


export const SvgWrap = styled.div`
  svg {
    path {
      stroke: #fff;
      stroke-width: 1px;
    }


    @media (min-width: 1280px) and (max-width: 1365px) {
      max-height: 300px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      max-height: 280px;
    }

    text {
      font-size: 32px;
      fill: #fff;
      font-family: kanit;
      font-weight: 200;
    }
  }
`
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Popup.scss';

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchProperties, closePopup } from 'actions/propertyActions';

class Popup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(this.props.properties.length === 0) {
      return null;
    }

    if(!this.props.popup) {
      return null;
    }

    var aptNum = this.props.popup;

    const data = this.props.propertyList.find(item => item.ibudarnumer == aptNum && item.hus == this.props.property);
    var href = '/' + this.props.property + '/' + data.ibudarnumer;

    var classNames = ['property-popover'];

    if(data.sold === "1") {
      classNames.push('property-popover-sold');
    }

    if(data.on_sale === "0") {
      classNames.push('property-popover-later');
    }
    // { data.hus !== 'hafnarbraut13-15' && <span className="property-popover-close" onClick={ this.props.closePopup }>&times;</span> }
    // { data.hus !== 'hafnarbraut13-15' && <Link to={ href } onClick={ this.props.closePopup }>Skoða nánar</Link> }


    /*
    <ul className="cf">
      { data.birt_staerd && <li key="birt_staerd"><strong>Stærð</strong><br />{ data.birt_staerd } m<sup>2</sup></li> }
      { data.herb && <li key="herb"><strong>Herbergi</strong><br />{ data.herb }</li> }
      { data.svalir_staerd && <li key="svalir_staerd"><strong>Svalir</strong><br />{ data.svalir_staerd } m<sup>2</sup></li> }
      { data.bilastaedi && <li key="bilastaedi"><strong>Stæði í bílakjallara</strong><br />{ data.bilastaedi }</li> }
      <li key="formatted_verd"><strong>Verð</strong><br />{ data.formatted_verd }</li>
    </ul>
    */

    return (
      <div className={ classNames.join(' ') }>        
        <h1>
          { data.title }
        </h1>
        <table>
          <tbody>
            { data.birt_staerd &&
              <tr key="birt_staerd">
                <td><strong>Stærð</strong></td>
                <td>{ data.birt_staerd } m<sup>2</sup></td>
              </tr>
            }
            { data.herb &&
              <tr key="herb">
                <td><strong>Herbergi</strong></td>
                <td>{ data.herb }</td>
              </tr>
            }
            { data.svalir_staerd &&
              <tr key="svalir_staerd">
                <td><strong>Svalir</strong></td>
                <td>{ data.svalir_staerd } m<sup>2</sup></td>
              </tr>
            }
            { data.bilastaedi &&
              <tr key="bilastaedi">
                <td><strong>Stæði í bílakjallara</strong></td>
                <td>{ data.bilastaedi }</td>
              </tr>
            }
            <tr key="formatted_verd">
              <td><strong>Verð</strong></td>
              <td className={ data.sold === "0" ? '' : 'td-sold' }>{ data.formatted_verd }</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Popup.propTypes = {
  fetchProperties: PropTypes.func.isRequired,
  propertyList: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  propertyList: state.property.items,
  popup: state.property.popup
});

export default connect(mapStateToProps, {
  fetchProperties,
  closePopup
})(Popup);

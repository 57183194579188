import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import PropertyPopup from './Popup.js';
import { Link } from 'react-router-dom';

import 'semantic/dist/components/popup.min.css';
import './Vector.scss';

class Vector extends Component {
  constructor(props) {
    super(props);

    this.renderVector = this.renderVector.bind(this);
    this.getPolygonClassName = this.getPolygonClassName.bind(this);
  }

  getPolygonClassName(aptNum) {
    var classes = [];

    this.props.properties.find(function(item) {
      if(item.ibudarnumer === aptNum && item.hus == this.props.property) {
        if(item.sold === "1") {
          classes.push('polygon-sold');
        }

        if(item.sold === "2") {
          classes.push('polygon-hold');
        }

        if(item.on_sale === "0") {
          classes.push('polygon-later');
        }
      }
    }.bind(this));

    if(this.props.popup === aptNum || this.props.hover === aptNum) {
      classes.push('polygon-hover');
    }

    return classes.join(' ');
  }

  // onClick={ this.props.openPopup }

  renderVector() {
    return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={ this.props.vector.viewBox || "0 0 1920 1080" }>
      { this.props.vector.paths.map( (item) => {
        let floor = item.key.substr(1, 1);

        if(floor === '-') {
          floor = item.key.substr(3, 1);
        }

        let href = '/' + this.props.property + '/';

        if(this.props.property === 'hafnarbraut12') {
          href += `${item.key.substr(0, 1)}/${item.key}`
        } else {
          href += item.key
        }

        return (
          <React.Fragment key={ item.key }>
            { this.props.browser.greaterThan.small &&
                <Popup context={ this.props.getRef( item.key )} open={ this.props.popup === item.key } position={ item.position }>
                  <PropertyPopup properties={ this.props.properties } property={ this.props.property } aptNumber={ item.key } />
                </Popup>
            }

            <Link to={ href } onClick={ this.props.closePopup } onMouseEnter={ () => { this.props.setCurrentFloor(floor) } }>
              <path ref={ (node) => { this.props.createRef(node) } }
                    data-key={ item.key }
                    onMouseEnter={ this.props.openPopup } 
                    onMouseLeave={ this.props.closePopup }
                    className={ this.getPolygonClassName(item.key) } d={ item.data } />
            </Link>
          </React.Fragment>
        )
      })
    }
    </svg>;
  }

  render() {
    if(this.props.properties.length === 0) {
      return <p>Loading...</p>;
    }

    if(!this.props.visible) {
      return null;
    }

    return (
      <div className="property-vector">
        <div className="svg-fluid-wrap">
          {this.props.children}
          <div className="svg-fluid svg-hus">
            <img src={ this.props.vectorImage.src } srcSet={ this.props.vectorImage.srcSet } sizes="100vw" />
            { this.renderVector() }
          </div>
        </div>
      </div>
    );
  }
}

export default Vector;
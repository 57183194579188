import {
  FETCH_PROPERTIES,
  FETCH_APARTMENTS,
  SET_FLOOR,
  LIST_HOVER,
  OPEN_POPUP,
  CLOSE_POPUP,
  CHANGE_VECTOR,
  RESET_PROPERTY_STATE,
  SET_ACTIVE_FLOOR
} from 'actions/types';

const initialState = {
  items: [],
  list: [],
  hover: null,
  popup: null,
  currentFloor: 1,
  currentVector: 'framhlid',
  dropdownFloorOptions: {
    'hafnarbraut9': [
      { label: '1. hæð', value: 1 },
      { label: '2. hæð', value: 2 },
      { label: '3. hæð', value: 3 },
      { label: '4. hæð', value: 4 },
    ],
    'hafnarbraut11': [
      { label: 'Vinnustofur', value: 0 },
      { label: '1. hæð', value: 1 },
      { label: '2. hæð', value: 2 },
      { label: '3. hæð', value: 3 },
      { label: '4. hæð', value: 4 }
    ],
    'hafnarbraut13-15': [
      { label: 'Kjallari', value: 0 },
      { label: '1. hæð', value: 1 },
      { label: '2. hæð', value: 2 },
      { label: '3. hæð', value: 3 },
      { label: '4. hæð', value: 4 }
    ],
    'hafnarbraut12': [
      { label: '1. hæð', value: 1 },
      { label: '2. hæð', value: 2 },
      { label: '3. hæð', value: 3 },
      { label: '4. hæð', value: 4 },
      { label: '5. hæð', value: 5 },
    ]
  }
};

export default function(state = initialState, action) {
  switch(action.type) {
    case RESET_PROPERTY_STATE:
      return Object.assign({}, initialState, {
        list: state.list,
        items: state.items,
        itemsByFloor: state.itemsByFloor,
      });
    case FETCH_APARTMENTS:
      var data = action.payload;
      var apartmentListByFloor = {};

      for(var num in data) {
        var floor = num.substr(1, 1);

        if(!apartmentListByFloor.hasOwnProperty(floor)) {
          apartmentListByFloor[floor] = [];
        }

        apartmentListByFloor[floor].push(data[num]);
      }

      return Object.assign({}, state, {
        items: action.payload,
        itemsByFloor: apartmentListByFloor
      });
    case FETCH_PROPERTIES:
      return Object.assign({}, state, {
        list: action.payload
      });
    case SET_ACTIVE_FLOOR:
      return Object.assign({}, state, {
        activeFloor: action.payload
      });
    case SET_FLOOR:
      return Object.assign({}, state, {
        currentFloor: action.payload
      });
    case LIST_HOVER:
      return Object.assign({}, state, {
        hover: action.payload
      });
    case OPEN_POPUP:
      return Object.assign({}, state, {
        popup: action.payload
      });
    case CLOSE_POPUP:
      return Object.assign({}, state, {
        popup: action.payload
      });
    case CHANGE_VECTOR:
      let newVector = action.payload;

      if(newVector.indexOf(',') >= 0) {
        newVector = newVector.split(',');

        if(newVector.includes(state.currentVector)) {
          return state;
        } else {
          newVector = newVector.shift();
        }
      }

      return Object.assign({}, state, {
        currentVector: newVector
      });
    default:
      return state;
  }
}
import React, { Component } from 'react';
import './IntroImage.scss';

class IntroImage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const imageReq = require.context('images/', true, /\.(png|jpg)$/);
    const image = imageReq(`./${this.props.src}`);
    const style = this.props.hasOwnProperty('style') ? this.props.style : {};
    const { headline, text, filterButtons } = this.props;

    return (
      <div className="intro-image-wrap">
        <div className="intro-image cf">
          <div className="intro-overlay"></div>
          <img src={ image.src } sizes="100vw" srcSet={ image.srcSet } />
        </div>
        <div className="intro-info">
          <div className="ui container">
            <h1>{ headline }</h1>
            <div style={ style }>{ text }</div>
            { filterButtons }
          </div>
        </div>
      </div>
    );
  }
}

export default IntroImage;

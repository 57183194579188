import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'reducers';
import ReduxThunk from 'redux-thunk';
import { responsiveStoreEnhancer } from 'redux-responsive';

const initialState = {};
const middleware = [ReduxThunk];
const store = createStore(
  rootReducer,
  initialState,
  compose(
    responsiveStoreEnhancer,
    composeWithDevTools(
      applyMiddleware(...middleware)
    )
  )
);

export default store;
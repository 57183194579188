import {
  SET_SPLASH_VECTOR,
  SET_MAP_FILTER
} from 'actions/types';

const initialState = {
  splashVector: 'nv',
  mapFilters: [
    { name: 'afthreying', checked: true },
    { name: 'veitingar', checked: true },
    { name: 'verslanir', checked: true },
    { name: 'skolar', checked: true },
    { name: 'thjonusta', checked: true }
  ]
};

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_SPLASH_VECTOR:
      return Object.assign({}, state, {
        splashVector: action.payload
      });
    case SET_MAP_FILTER:
      return Object.assign({}, state, {
        mapFilters: state.mapFilters.map(mapFilter =>
          mapFilter.name === action.payload.name ? { name: action.payload.name, checked: action.payload.checked } : mapFilter
        )
      });
    default:
      return state;
  }
}
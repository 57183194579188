export const FETCH_APARTMENTS = 'FETCH_APARTMENTS';
export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const SET_ACTIVE_FLOOR = 'SET_ACTIVE_FLOOR';
export const SET_FLOOR = 'SET_FLOOR';
export const LIST_HOVER = 'LIST_HOVER';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const RESET_PROPERTY_STATE = 'RESET_PROPERTY_STATE';
export const CHANGE_VECTOR = 'CHANGE_VECTOR';
export const SET_SPLASH_VECTOR = 'SET_SPLASH_VECTOR';

export const IS_NAV_OPEN = 'IS_NAV_OPEN';
export const SET_NAV_STATE = 'SET_NAV_STATE';

export const SET_MAP_FILTER = 'SET_MAP_FILTER';
import React, { Component } from 'react';
import { useCookies } from 'react-cookie';
import TvihorfLogo from 'vectors/logos/tvihorf.js';
import AfhusLogo from 'vectors/logos/afhus.js';
import RemaxLogo from 'vectors/logos/remax.js';
import LindLogo from 'vectors/logos/lind.js';
import './Agent.scss';

function shuffle(array) {
  var m = array.length, t, i;

  while (m) {
    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

const AGENCIES_DATA = [
  {
    name: 'lind',
    logo: LindLogo,
    domain: 'fastlind.is',
    tel: '+354 510 7900',
    agents: [
      {
        image: require('images/soluadilar/lind-stefan.png'),
        firstName: 'Stefán',
        lastName: 'Jarl',
        tel: '892 9966',
        email: 'stefan@fastlind.is'
      },
      {
        image: require('images/soluadilar/lind-kristjan.png'),
        firstName: 'Kristján Þórir',
        lastName: 'Hauksson',
        tel: '696 1122',
        email: 'kristjan@fastlind.is',
      },
      {
        image: require('images/soluadilar/lind-elvar.png'),
        firstName: 'Elvar Frímann',
        lastName: 'Frímannsson',
        tel: '659 6606',
        email: 'elvar@fastlind.is',
      }
    ]
  },
  {
    name: 'remax',
    logo: RemaxLogo,
    domain: 'remax.is',
    tel: '+354 414 4700',
    agents: [
      {
        image: require('images/soluadilar/remax-thorsteinn.png'),
        firstName: 'Þorsteinn',
        lastName: 'Yngvason',
        tel: '696 0226',
        email: 'thorsteinn@remax.is'
      },
      {
        image: require('images/soluadilar/remax-magnus.png'),
        firstName: 'Magnús Már',
        lastName: 'Lúðvíksson',
        tel: '699 2010',
        email: 'maggi@remax.is'
      }
    ]
  }
]

shuffle(AGENCIES_DATA)

function Agent(props) {
  const [cookies] = useCookies(['ref']);
  const ref = cookies.ref
  const soluadilar = ['lind', 'remax'];

  let visibleSoluadilar = ref ? [ ref ] : soluadilar;

  const shouldShow = name => (
    visibleSoluadilar.indexOf(name) !== -1
  )

  return (
    <div className="property-agencies-container ui container" data-ref="soluadilar" ref={(node) => { props.createRef(node) }}>          
      <div>
        <div style={{ paddingBottom: '25px' }}>
          <h2 style={{ paddingBottom: '15px' }}>Byggingaraðili</h2>
          <a href="http://afhus.is/" rel="noopener noreferrer" target="_blank">
            <AfhusLogo />
          </a>
        </div>
        <div style={{ paddingBottom: '25px' }}>
          <h2 style={{ paddingBottom: '15px' }}>
            Arkitekt
          </h2>
          <TvihorfLogo />
        </div>
      </div>

      <h2>Söluaðilar</h2>
      <p>Hægt er að hafa samband við eftirfarandi<br />söluaðila fyrir frekari upplýsingar</p>
      <div className="property-agencies" data-count={visibleSoluadilar.length}>
        {AGENCIES_DATA.map(agency => shouldShow(agency.name) ? (
          <div key={agency.name} className="property-agency" data-agency={agency.name}>
            <div>
              <div className="property-agency-logo">
                <a href={`https://${agency.domain}`} target="_blank" rel="noopener noreferrer">
                  <agency.logo />
                </a>
              </div>
              <ul>
                <li>
                  <a href={`https://${agency.domain}`} target="_blank" rel="noopener noreferrer">
                    {agency.domain}
                  </a>
                </li>
                <li>
                  <a href={`tel:${agency.tel}`} rel="noopener noreferrer">
                    {agency.tel}
                  </a>
                </li>
              </ul>
            </div>
            
            <div className="property-agents">
              {agency.agents.map(agent => (
                <div className="property-agent" key={agent.email}>
                  <img src={agent.image} alt={`${agent.firstName} ${agent.lastName}`} />
                  <ul>
                    <li>{agent.firstName}<br />{agent.lastName}</li>
                    <li><a href={`tel:${agent.tel}`}>Sími: {agent.tel}</a></li>
                    <li>
                      <a href={`mailto:${agent.email}`}>
                        {agent.email}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${agent.email}`} className="btn-contact">
                        Senda fyrirspurn
                      </a>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : null)}
      </div>
    </div>
  );
}

export default Agent;

import React from "react";

const SvgFacebookWhite = props => (
  <svg viewBox="0 0 113.62 218.8" {...props}>
    <path
      d="M73.75 218.8v-99.81h33.5l5-38.89h-38.5V55.27c0-11.26 3.13-18.94 19.27-18.94h20.6V1.49a276.68 276.68 0 0 0-30-1.53c-29.7 0-50 18.13-50 51.41v28.69H0v38.93h33.58v99.81z"
      fill="#fff"
    />
  </svg>
);

export default SvgFacebookWhite;


import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nav from 'components/Nav/Nav.js';
import PropertyList from 'components/Property/List.js';
import PropertyArea from 'components/Property/Area.js';
import PropertyVector from 'components/Property/Vector.js';
import Agent from 'components/Agent/Agent.js';
import Footer from 'components/Footer/Footer.js';
import Explanation from './Explanation';
import PropertySingleEmbed from 'components/Property/SingleEmbed.js';
import FloorMap from './FloorMap-v2/FloorMap';
import FloorOverview from './FloorMap-v2/FloorOverview';

import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

import { Carousel } from 'react-responsive-carousel';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchProperties, fetchApartments, setActiveFloor, setCurrentFloor, onHover, openPopup, closePopup, changeVector, resetPropertyState } from 'actions/propertyActions';

import './Listings.scss';

class Property extends Component {
  pathRefs = {};
  hasScrolled = false;
  syningaribud = null;
  imageCache = null;
  unlisten = null;
  currentHash = null;
  onload = null;
  scrollRefs = {};

  state = {
    currentImage: 0,
    lightboxIsOpen: false,
    gallery: ''
  };

  componentWillMount() {
    this.props.fetchProperties();
    this.props.fetchApartments();

    if(!this.imageCache) {
      const that = this;

      function importAll(r) {
        that.imageCache = {};

        r.keys().forEach(key => that.imageCache[key.replace('./', 'images/')] = r(key));
      }

      importAll(
        require.context('images/', true, /\.jpg$/)
      );
    }
  }

  resetRefs = () => {
    this.pathRefs = {};
  }

  createRef = (node) => {
    if(node) {
      var ref = node.getAttribute('data-key');
      this.pathRefs[ref] = node;
    }
  }

  createScrollRef = (node) => {
    if(node) {
      var ref = node.getAttribute('data-ref');
      if(this.scrollRefs.hasOwnProperty('#/' + ref)) {
        this.scrollRefs['#/' + ref] = node;
      }
    }
  }

  getRef = (refNum) => {
    return this.pathRefs.hasOwnProperty(refNum) ? this.pathRefs[refNum] : null;
  }

  scrollToRef = (opts) => {
    var ref = null;

    if(opts.ref) {
      ref = this.scrollRefs.hasOwnProperty(opts.ref) ? this.scrollRefs[opts.ref] : false;
    } else if(this.currentHash) {
      ref = this.scrollRefs.hasOwnProperty(this.currentHash) ? this.scrollRefs[this.currentHash] : false;
    }

    if(ref) {
      var offsetTop = ref.offsetTop || ref.offsetParent.offsetTop;
      var topOfElement = offsetTop - 100;

      if(opts.delay) {
        setTimeout(function() {
          window.scroll({
            top: topOfElement,
            behavior: 'smooth'
          });
        }, 400);
      } else {
        window.scroll({
          top: topOfElement,
          behavior: 'smooth'
        });
      }
      //this.hasScrolled = true;
    } else {
      // window.scrollTo(0, 0);
    }
  }

  scrollToApartment = (apartment) => {
    var opts = { delay: true };

    if(apartment) {
      opts.ref = '#/ibud';
    }

    this.scrollToRef(opts);
  }

  componentDidUpdate(prevProps, prevState) {
    // if(prevProps.apartments !== this.props.apartments && !this.hasScrolled) {
    // if(prevProps.apartments !== this.props.apartments && !this.hasScrolled) {
    if(this.props.match.params.id !== prevProps.match.params.id) {
      this.scrollToApartment(this.props.match.params.id);
    }

    if(!this.hasScrolled && this.props.match.params.id) {
      setTimeout(() => this.scrollToApartment(this.props.match.params.id), 1000);
      this.hasScrolled = true;
    }
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    this.currentHash = this.props.history.location.hash;
    this.scrollRefs = {
      '#/ibud': null,
      '#/ibudir': null,
      '#/soluadilar': null,
      '#/umhverfi': null,
      '#/thjonusta': null,
      '#/svaedid': null,
      '#/syningaribudir': null
    };

    this.unlisten = this.props.history.listen((location, action) => {
      this.currentHash = location.hash;

      if(this.currentHash) {
        this.scrollToRef({ delay: true, ref: this.currentHash });
      }
    });

    if(document.readyState == 'complete' || document.readyState == 'loaded') {
      const currentApartment = this.props.match.params.id;

      if(!currentApartment) {
        window.scrollTo(0, 0);
      } else {
        this.scrollToApartment(this.props.match.params.id);
      }
    } else {
      // document has not been loaded yet,
      // setup a onload listener -> scroll to element when page has loaded

      // window.addEventListener('load', this.onload);
    }
  }

  componentWillUnmount() {
    this.props.resetPropertyState();
    this.unlisten();
    this.currentHash = null;
    this.hasScrolled = false;
    this.state = {};
    this.scrollRefs = {};
  }

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {
    if(this.props.properties.length === 0 || this.props.apartments.length === 0) {
      return <p>Loading...</p>;
    }

    const currentProperty = this.props.match.params.hus || 'hafnarbraut14';
    let afangi = parseInt(this.props.match.params.afangi, 10);

    if(!afangi) {
      afangi = 1;
    }

    const currentApartment = this.props.match.params.id;
    const showApartment = currentApartment ? true : false;
    const showVector = showApartment ? false : true;
    const property = this.props.properties[currentProperty];
    const that = this;

    const sizes = ['100vw'];
    const headline = property.title;
    const paragraph = property.text.intro;
    const links = property.links;
    let vectors = currentProperty === 'hafnarbraut12' ? property.afangar[afangi].vectors : property.vectors;
    let currentVector = vectors[this.props.currentVector];
    let currentVectorImage = this.imageCache[vectors[this.props.currentVector].image];

    this.syningaribudImages = [];
    this.sizes = ['(min-width: 1024px) 218px, (min-width: 1440px) 192px, 192px'];

    const images = property.images;
    const syningaribud = property.syningaribud;

    syningaribud.map(syningaribudImagePath => {
      var syningaribudImage = this.imageCache[syningaribudImagePath];
      const basename = syningaribudImage.src.split(/[\\/]/).pop();
      const title = basename.split('-')[0];

      const titles = {
        '3ja': 'Íbúð 0314',
        'jarðhaed': 'Íbúð 0102',
        'penthouse': 'Íbúð 0501',
        'studio': 'Íbúð 0307'
      }

      this.syningaribudImages.push({
        src: syningaribudImage.src,
        srcSet: syningaribudImage.srcSet,
        sizes: this.sizes,
        width: 192,
        height: 128,
        alt: titles[title],
        caption: titles[title]
      });
    });

    var syningaribudHeadlines = {
      'hafnarbraut9': 'Sýningaríbúð',
      'hafnarbraut14': '3D myndir af íbúðum',
      'hafnarbraut11': 'Myndir af íbúðum',
      'hafnarbraut12': 'Sýningaríbúð (302)',
      'hafnarbraut13-15': '3D myndir af íbúð (215/315/413)'
    };

    let galleryColumnSizes = {
      'hafnarbraut9' : 6,
      'hafnarbraut11' : 6,
      'hafnarbraut12' : 2,
      'hafnarbraut13-15': 2,
      'hafnarbraut14': 6
    };

    if(this.syningaribudImages.length > 0) {
      let photoCount = 12;

      if(property.slug === 'hafnarbraut14') {
        photoCount = 18;
      }

      this.syningaribud = (
        <div className={ 'property-syningaribud-wrap cf' }>
          <div className="property-syningaribud">
            <h2>{ syningaribudHeadlines[property.slug] }</h2>
            <Gallery columns={ galleryColumnSizes[property.slug] } photos={ this.syningaribudImages.slice(0, photoCount) } onClick={ this.openLightbox } targetRowHeight={ 128 }/>
            <Lightbox className="LazyLoad" images={ this.syningaribudImages }
                      onClose={ this.closeLightbox } onClickPrev={ this.gotoPrevious }
                      onClickNext={ this.gotoNext }
                      currentImage={ this.state.currentImage }
                      isOpen={ this.state.lightboxIsOpen }
                      preloadNextImage={ false } />
          </div>

          { property.slug === 'hafnarbraut12' && (
            <div className="property-360tour">
              <h2>Sýningaríbúð 302 - 360°</h2>
              <div className="video-fluid" style={{ paddingBottom: '90%' }}>
                <iframe border="0" style={{ border: '2px solid transparent' }} src="https://www.karsnes.is/360tour/hafnarbraut12/302/index.htm"></iframe>
              </div>
            </div>
          )}
        </div>
      );
    }

    var propertyListOrPlan = (
      <>
        <PropertyList visibleVector={ showVector } property={ currentProperty } toggleOverlay={ this.props.hover } togglePopup={ this.props.openPopup } />
        <Explanation property={ currentProperty } />
      </>
    );

    var showEignirHeadline = true;
    var floorMapJson = null;

    if(currentProperty === 'hafnarbraut13-15' || currentProperty === 'hafnarbraut12' || currentProperty === 'hafnarbraut14') {
      const floorMapsJson = {
        'hafnarbraut13-15': require('./FloorMap-v2/hafnarbraut13-15.json'),
        'hafnarbraut12-1': require('./FloorMap-v2/hafnarbraut12-1.json'),
        'hafnarbraut12-2': require('./FloorMap-v2/hafnarbraut12-2.json'),
        'hafnarbraut14': require('./FloorMap-v2/hafnarbraut14.json')
      }

      showEignirHeadline = false;

      if(currentProperty === 'hafnarbraut12') {
        floorMapJson = floorMapsJson[currentProperty + '-' + afangi]
      } else {
        floorMapJson = floorMapsJson[currentProperty]
      }

      propertyListOrPlan = (
        <>
          {currentProperty === 'hafnarbraut12' && (
            <div style={{ textAlign: 'center' }}>
              <h3 style={{
                color: '#367384',
                textAlign: 'center',
                margin: '0 0 6px 0'
              }}>
                Veldu hús
              </h3>
              <svg viewBox="0 0 1492.01 1003.93" className="select-house-svg">
                <g>
                  <path fill="#f2af29" d="M910.88 345.54l-8.83-25.62 8.27-2.84V317l.03-.01-.02-67.78v-47.07h-.01L910.3.01h-86.36V0l-6.71.01h-.24l-45.93.03h-.34v.09h-.02v33.83h-47.54V.04h-53.22v.01h-.09v32.32l-47.58-.04V18.96h-3.36v-.03h-.09v-.01l-15.63.06-81.7.11v.22h-.22v.09h-.01l.02 14.65-52.92.01v.01h-.08v35.8l-47.47-.01V44.77h-53.32V80.8l-51.22-.02.05-55.05h-.04v-.05l-97.16-.01.01-19.14h-3.82v-.01l-97.41-.08-108.52-.1v.09h-.01v141.52h22.76-.03l.02 40.68H18.53l.02 26.73h13.54v18.46h.33H5.83l-.12 103.7h.07-.07l-5.71.07v119.56l129.85-.01-.07 10.16 104.22.02v-30.35h.03l.03-118.85h4.05v-.02h.01v-2.37h7.78l.01-85.05h-35.04v-17.81l-25.44-.01h-.01.01l-.01-25.34 106.68-.02.11 34.78h.08v.08h23.98v-.07h.01v.07h32.66l.01 4.4h27.93l-.03-4.4h43.92v-.08h.03l.01-10.52h34.45l.02 4.42h27.89v-.01h.01l.02-4.5h13.44v-3.63l49.64.05 28.48 82.67 38.36-13.21.01-27.27h53.51l15.22 44.75-49.03 16.88-6.32-18.34-45.4 15.63 12.63 36.72-27.53 9.48 24.27 70.22 27.53-9.25.39 1.14 94.46-32.52.01.02 56.57-19.43 8.77 25.73 43.27-14.76-.03-.07.04-.02-8.79-25.77.52-.17 45.55-15.7 8.85 25.69 43.23-14.88-.02-.07.05-.02z"/>
                  <text fill="#fff" fontFamily="kanit" fontSize="72" fontWeight="200" transform="translate(160 158)">
                    Kemur í sölu síðar
                  </text>                  
                </g>
                <Link to='/hafnarbraut12/1/'>
                  <path fill={ afangi === 1 ? '#377488' : ' #eee' } d="M1439.7 611.85h-175.43V606h-13V219.31l13-13v-4.19h-94.7v17.25h43.53v45.51h-115.96v-27.66h-51.06v25.18h-33.35v.52H947.8V83.88h35.54V56.7h56.22V39.26h44.54v-1.47h56.25V20.37h44.54V18.9h54.33V5.58h45.35V0h60.22v5.58H1406l34.13 34.12-10.25 10.3 36.17 36.18v10.64h26v51.91h-26V196h9.14l1.9 85.83h-11v23.2h-22v18.86h33.79l1.75 79.74 1.76 79.73h-37.3v18.84H1466v23.23h16.21l1.9 85.82h-39.53v.54zm-718.37 391.86l12.81-2.15 8.61-1.44 73.07-12.23V989H939v-32.5h114v-29.16h-11.3v-44.55h100.54l57.59-10.17h86.74v-20.28h1.08v-28.47h26.57v23.76h.58v33.9h32.12v41.58h104.73v-5h26.51V866.2h-26.51v-71.77h-7.56v-38.89h43.14l-2.37-107.24H1346.9v20.85h-127.41v25.19h-100.78v25.18h-100.8v25.19H917.16v27.07h-53v34.52h-46.88v-34.44H742.7v39.52h-.44v37.24h-57.92v39h-3z"/>
                  <text fill={ afangi === 1 ? '#fff' : '#377488' } fontFamily="kanit" fontSize="72" fontWeight="200" transform="translate(1137.79 137.62)">
                    HÚS 1
                  </text>
                </Link>
              </svg>
            </div>
          )}
          <FloorOverview
            data={ floorMapJson }
            setSelectedFloor={ this.props.setCurrentFloor }
            selectedFloor={ this.props.currentFloor }
            activeFloor={ this.props.activeFloor }
            setActiveFloor={ this.props.setActiveFloor }
            />
          {currentProperty === 'hafnarbraut12' && (
            <Explanation property={ currentProperty } />
          )}

          <FloorMap
            apartmentData={ this.props.apartments }
            data={ floorMapJson }
            currentProperty={ currentProperty }
            afangi={ afangi }
            setActiveApartment={ this.props.onHover }
            openPopup={ this.props.openPopup }
            setActiveSide={ this.props.changeVector }
            activeFloor={ this.props.activeFloor }
            selectedFloor={ this.props.currentFloor }
            activeApartment={ this.props.hover }
            selectedApartment={ null }
          />
          {currentProperty !== 'hafnarbraut12' && (
            <Explanation property={ currentProperty } />
          )}
        </>
      );
    }

    let vectorSwitchText = 'Skoða framhlið';

    if(this.props.currentVector === 'framhlid') {
      vectorSwitchText = 'Skoða bakhlið';
    }

    if(currentProperty === 'hafnarbraut12' && afangi === '1') {
        vectorSwitchText = 'Skoða bakhús';
    }

    let viewSwitch = (
      <button className="switch-vector"
          onClick={ this.props.changeVector }
          data-side={ this.props.currentVector === 'framhlid' ? 'bakhlid' : 'framhlid' }>
        { vectorSwitchText }
      </button>
    )

    if(currentProperty === 'hafnarbraut14') {
      const viewSwitches = {
        'framhlid': [
          { side: 'view5', text: '« Bílastæði norðaustur' },
          { side: 'view4', text: '» Gata suðausturs' }
        ],
        'view4': [
          { side: 'framhlid', text: '« Gata suðvesturs' },
          { side: 'view6', text: '» Bílastæði norðvestur' },
        ],
        'view6': [
          { side: 'view4', text: '« Gata suðausturs' },
          { side: 'view5', text: '» Bílastæði norðaustur' }
        ],
        'view5': [
          { side: 'view6', text: '« Bílastæði norðvestur' },
          { side: 'framhlid', text: '» Gata suðvesturs' }
        ]
      }

      viewSwitch = (
        <>
          <p className="svg-switch-help-text">Notið flipana til hliðar til að fletta á milli sjónarhorna</p>
          { viewSwitches[this.props.currentVector].map((item, idx) => (
              <button
                className={`svg-switch-btn ` +  (idx === 0 ? 'svg-switch-btn-left' : 'svg-switch-btn-right') }
                key={ item.side }
                data-side={ item.side }
                onClick={ this.props.changeVector }>{ item.text }</button>
          ))}
        </>
      )
    }

    return (
      <>
        <Nav links={ links } />
        <div className={ `page-wrap page-listings ` + currentProperty }>
          { currentProperty === 'hafnarbraut14' && (
            <div className="carousel-help-text">
              <div className="ui container text-center">
                <p>
                  Notaðu örvarnar á myndinni til að skoða fleiri sjónarhorn
                </p>
              </div>
            </div>
          )}
          <div className="intro-image-wrap">
            <div className="intro-image intro-carousel cf">
              { this.imageCache &&
                <Carousel infiniteLoop={ true } showIndicators={ false } showThumbs={ false } dynamicHeight={ true }>
                  { images.map((item, key) =>
                    <img key={ item }src={ this.imageCache[images[key]].src } srcSet={ this.imageCache[images[key]].srcSet } sizes={ sizes }/>
                  )}
                </Carousel>
              }
            </div>
            { currentProperty !== 'hafnarbraut14' && (
              <div className="intro-info intro-info-carousel">
                <div className="ui container">
                  <h1>{ headline }</h1>
                  <p>{ paragraph }</p>
                  <ul className="cf search-filter-buttons">
                    { (currentProperty === 'hafnarbraut13-15' || currentProperty === 'hafnarbraut14') &&
                      <li key="room_filter_studio">
                        <Link to={ '/ibudir/leit?rooms=1&property=' + currentProperty }>Stúdíó</Link>
                      </li>
                    }
                    <li key="room_filter_2br">
                      <Link to={{ pathname: '/ibudir/leit', search: '?rooms=2&property=' + currentProperty }}>2 herbergja íbúðir</Link>
                    </li>
                    <li key="room_filter_3br">
                      <Link to={ '/ibudir/leit?rooms=3&property=' + currentProperty }>3 herbergja íbúðir</Link>
                    </li>
                    { (currentProperty === 'hafnarbraut9' || currentProperty === 'hafnarbraut13-15' || currentProperty === 'hafnarbraut14') &&
                      <li key="room_filter_4br">
                        <Link to={ '/ibudir/leit?rooms=4&property=' + currentProperty }>4 herbergja íbúðir</Link>
                      </li>
                    }

                    { currentProperty === 'hafnarbraut14' && (
                      <li key="room_filter_5br">
                        <Link to={ '/ibudir/leit?rooms=5&property=' + currentProperty }>5 herbergja íbúðir</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>

          {
            currentProperty === 'hafnarbraut14' && (
              <div className="property-intro">
                <div className="ui container">
                  <h1>{ headline }</h1>
                  <p>{ paragraph }</p>
                  <ul className="cf search-filter-buttons">
                    <li key="room_filter_studio">
                      <Link to={ '/ibudir/leit?rooms=1&property=' + currentProperty }>Stúdíó</Link>
                    </li>
                    <li key="room_filter_2br">
                      <Link to={{ pathname: '/ibudir/leit', search: '?rooms=2&property=' + currentProperty }}>2 herbergja íbúðir</Link>
                    </li>
                    <li key="room_filter_3br">
                      <Link to={ '/ibudir/leit?rooms=3&property=' + currentProperty }>3 herbergja íbúðir</Link>
                    </li>
                    <li key="room_filter_4br">
                      <Link to={ '/ibudir/leit?rooms=4&property=' + currentProperty }>4 herbergja íbúðir</Link>
                    </li>
                    <li key="room_filter_5br">
                      <Link to={ '/ibudir/leit?rooms=5&property=' + currentProperty }>5 herbergja íbúðir</Link>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }

          { property.about &&
            <div className="ui container secondary-text text-center">
              <p>
                { property.about }
              </p>
            </div>
          }

          <div data-ref="syningaribudir" ref={ (node) => { this.createScrollRef(node) } }>
            { this.syningaribud }
          </div>

          <div className="property" data-ref="ibudir" ref={ (node) => { this.createScrollRef(node) } }>
            <div className="property-list">
              { showEignirHeadline && <h2>Eignir</h2> }

              { propertyListOrPlan }              
            </div>

            {/* this.props.browser.lessThan.medium && <PropertyPopup properties={ this.props.properties } property={ currentProperty } open={ this.props.popup } aptNumber={ this.props.popup }/> */}

            <PropertyVector property={ currentProperty }
                            properties={ this.props.apartments }
                            vector={ currentVector }
                            browser={ this.props.browser }
                            popup={ this.props.popup }
                            openPopup={ this.props.openPopup }
                            closePopup={ this.props.closePopup }
                            hover={ this.props.hover }
                            vectorImage={ currentVectorImage }
                            resetRefs={ this.resetRefs }
                            side={ this.props.currentVector }
                            togglePopup={ this.props.openPopup }
                            getRef={ this.getRef }
                            createRef={ this.createRef }
                            open={ this.props.openPopup }
                            overlay={ this.props.hover }
                            visible={ showVector }
                            setCurrentFloor={ this.props.setCurrentFloor }>
                              { showVector && viewSwitch }
                            </PropertyVector>

            <div data-ref="ibud" ref={ (node) => { this.createScrollRef(node) } }>
              {
                showApartment &&
                  <div className="property-vector">
                    <PropertySingleEmbed hus={ currentProperty } id={ currentApartment } />
                  </div>
              }
            </div>
          </div>

          <Agent createRef={ this.createScrollRef } property={ property.slug } />
          <PropertyArea createRef={ this.createScrollRef } />
          <Footer />
        </div>
      </>
    );
  }
}

Property.propTypes = {
  fetchProperties: PropTypes.func.isRequired,
  fetchApartments: PropTypes.func.isRequired,
  propertyList: PropTypes.object.isRequired,
  currentFloor: PropTypes.number.isRequired,
  activeFloor: PropTypes.number.isRequired,
  setCurrentFloor: PropTypes.func.isRequired,
  setActiveFloor: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  hover: PropTypes.string.isRequired,
  popup: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  changeVector: PropTypes.func.isRequired,
  resetPropertyState: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  apartments: state.property.items,
  properties: state.property.list,
  currentFloor: state.property.currentFloor,
  activeFloor: state.property.activeFloor,
  hover: state.property.hover,
  popup: state.property.popup,
  currentVector: state.property.currentVector,
  browser: state.browser
});

export default connect(mapStateToProps, {
  fetchProperties,
  fetchApartments,
  setCurrentFloor,
  onHover,
  openPopup,
  closePopup,
  changeVector,
  resetPropertyState,
  setActiveFloor
})(Property);
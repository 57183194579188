import {
  IS_NAV_OPEN,
  SET_NAV_STATE
} from './types';

export const setNavigationState = bool => dispatch => {
  dispatch({
    type: SET_NAV_STATE,
    payload: bool
  });
}
import React, { Component } from 'react';
import classNames from 'classnames';
import { StyledSvg, FloorGroup, List, ListItem, Headline } from './styles';

class FloorOverview extends Component {
  render() {
    const floors = this.props.data.floors;
    const activeFloor = this.props.activeFloor || this.props.selectedFloor;
    return (
      <div className="floor-overview">
        <Headline>Veldu hæð</Headline>
        <List listWidth={floors.length * 32}>
            {
              floors.map(floorItem => {
                const floor = floorItem.floor;
                const props = this.props;

                const liProps = {
                  key: 'floor-overview-list-item-' + floor,
                  className: classNames({
                    'active': activeFloor == floor
                  }),
                  onClick: props.setSelectedFloor.bind(this, floor),
                  onMouseEnter: props.setActiveFloor.bind(this, floor),
                  // onMouseLeave: props.setActiveFloor.bind(this, null)
                }

                return (
                  <ListItem { ...liProps }>
                    <span>{ floor }</span>
                  </ListItem>
                )
              })
            }
        </List>
      </div>
    )
  }
}

export default FloorOverview;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'
import Nav from 'components/Nav/Nav.js';
import IntroImage from 'components/IntroImage/IntroImage.js';
import Agent from 'components/Agent/Agent.js';
import Footer from 'components/Footer/Footer.js';
import PropertyArea from './Area.js';
import NorthIcon from 'vectors/icons/north.js';
import SouthIcon from 'vectors/icons/south.js';
import WestIcon from 'vectors/icons/west.js';
import './SingleEmbed.scss';

// import Modal from 'react-modal';

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchApartments, fetchProperties, setCurrentFloor, onHover, openPopup } from 'actions/propertyActions';

/*
Modal.setAppElement('#app');

const customStyles = {
  content : {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)'
  }
};
*/

class SingleProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.goBack = this.goBack.bind(this);
    this.createRef = this.createRef.bind(this);

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.iframe.src =
      'https://www.landsbankinn.is/einstaklingar/lan-og-fjarmognun/ibudalan/?reiknivel=opin&price=25000000&loanamount=15000000';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  createRef() {}

  componentWillMount() {
    this.props.fetchApartments();
    this.props.fetchProperties();
  }

  goBack() {
    window.history.back();
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
  }

  render() {
    /*
       <Modal
          isOpen={ this.state.modalIsOpen }
          onAfterOpen={ this.afterOpenModal }
          onRequestClose={ this.closeModal }
          style={ customStyles }
          contentLabel="Example Modal"
        >
          <button onClick={ this.closeModal }>close</button>
          <iframe ref={ iframe => this.iframe = iframe }></iframe>
        </Modal>

        <button onClick={ this.openModal }>Open Modal</button>
    */

    this.id = this.props.id;
    this.property = this.props.hus;

    if(this.props.apartments.length === 0 || this.props.properties.length === 0) {
      return null;
    }
    const directionIcons = {
      'hafnarbraut9'  : <NorthIcon />,
      'hafnarbraut11' : <SouthIcon />,
      'hafnarbraut14' : <WestIcon />
    };

    const directionIcon = directionIcons[this.property];
    const property = this.props.properties[this.property];
    const headline = property.title;
    const imageSrc = property.folder + '/grunnmyndir/' + this.id + '.png';
    const imageReq = require.context('images/', true, /\.png$/);
    const image = imageReq(`./${imageSrc}`);
    const data = this.props.apartments.find(item => item.ibudarnumer == this.id && item.hus == this.property);
    const paragraphs = {
      'ibud': property.text.single,
      'vinnustofa': 'Hafið samband við fasteignasala fyrir nánari upplýsingar um vinnustofur',
      'atvinnuhusnaedi': 'Hafið samband við fasteignasala fyrir nánari upplýsingar um atvinnuhúsnæði',
      'skrifstofa': 'Hafið samband við fasteignasala fyrir nánari upplýsingar um skrifstofur'
    };
    const paragraph = paragraphs[data.type];
    // const paragraph = data.floor === '0' ? 'Hafið samband við fasteignasala fyrir nánari upplýsingar um vinnustofur' : property.text.single;

    var imageTwo = null;
    if(data.floors === '2') {
      var floor = parseInt(this.id.substr(1, 1), 10);
      var imageTwoSrc = property.folder + '/grunnmyndir/' + '0' + (floor + 1) + this.id.substr(2) + '.png';

      if(this.property === 'hafnarbraut14') {
        imageTwoSrc = `${property.folder}/grunnmyndir/${this.id}-${floor+1}.png`;
      }

      imageTwo = imageReq(`./${imageTwoSrc}`);
    }

    var value, sqm = null, i;
    var listItems = [];
    var values = {
      'Stærð': 'birt_staerd',
      'Herbergi': 'herb',
      'Salerni': 'salerni',
      'Stæði í bílakjallara': 'bilastaedi',
      'Svalir': 'svalir_staerd',
      'Svalir 2': 'svalir2_staerd',
      'Verönd': 'verond_staerd',
      'Geymsla': 'geymsla_staerd',
    };

    if(property.slug === 'hafnarbraut14') {
      delete values['Geymsla'];
      values['Geymsla í bílakjallara'] = 'geymsla_staerd';
      values['Geymslunr.'] = 'geymsla_nr';
      values['Þaksvalir'] = 'thaksvalir_staerd';
      values['Þaksvalir 2'] = 'thaksvalir2_staerd';
      values['Svalaskýli'] = 'svalaskyli_staerd';

      if(['0111', '0112'].includes(this.id)) {
        delete values['Svalir'];
        delete values['Svalir 2'];

        values['Svalir á neðri hæð'] = 'svalir_staerd';
        values['Svalir á efri hæð'] = 'svalir2_staerd';
      }

      if(['0110', '0111', '0112'].includes(this.id)) {
        values['Sérinngangur'] = 'serinngangur';
        data['serinngangur'] = 'Já';
      }
    }

    if(property.slug === 'hafnarbraut11') {
      values['Útipallur'] = 'utipallur_staerd';
      values['Sérafnotareitur'] = 'serafnotareitur_staerd';
    }

    for(var key in values) {
      sqm = null;
      value = values[key];

      if(value.indexOf('staerd') !== -1) {
        sqm = <React.Fragment>m<sup>2</sup></React.Fragment>;
      }

      if(data[value]) {
        listItems.push(
          <li key={ value }><strong>{ key }</strong><span>{ data[value] } { sqm }</span></li>
        );
      }
    }

    if(data.formatted_verd !== "0") {
      listItems.push(<li key="verd"><strong>Verð</strong><span className={ data.sold === '0' ? '' : 'li-sold' }>{ data.formatted_verd }</span></li>);
    }
    if(data.afhending !== '') {
      listItems.push(<li key="afhending"><strong>Afhending</strong><span>{ data.afhending }</span></li>)
    }

    const links = property.links;

    var leftGridSize = 'seven';
    var rightGridSize = 'nine';

    if(property.slug == 'hafnarbraut11' || property.slug == 'hafnarbraut13-15') {
      leftGridSize = 'six';
      rightGridSize = 'ten';
    }

    let kjallariLink = null;
    let kjallariText = null;

    if(property.slug == 'hafnarbraut14') {
      leftGridSize = 'five';
      rightGridSize = 'eleven';

      if(property.kjallari) {
        kjallariLink = property.kjallari.grunnmynd || null;
        kjallariText = property.kjallari.text || null;
      }
    }

    var loanLink = null;

    if(data.sold === "0" && data.formatted_verd !== "0") {
      const loanAmount = data.verd * 0.8;
      // loanLink = 'https://www.landsbankinn.is/einstaklingar/lan-og-fjarmognun/ibudalan/?reiknivel=opin&price=' + data.verd + '&loanamount=' + (data.verd * 0.85);
      loanLink = `https://www.arionbanki.is/einstaklingar/lan/ibudalan/?LoanAmount=${loanAmount}&MarketValue=${data.verd}&PropertyValue=${data.verd}&type=kaupsamningur`;
    }

    let returnHref = '/' + this.property;

    if(this.property === 'hafnarbraut12') {
      returnHref += '/' + data.ibudarnumer.substr(0, 1) + '/';
    }

    return (
      <React.Fragment>
        <div className="property-details">
          <div className="property-return-bar cf">
            <Link to={ returnHref } className="property-return-bar-link">« Til baka í yfirlitsmynd</Link>
            <Link to={ returnHref } className="property-return-bar-close">X</Link>
          </div>
          <div className="ui grid stackable">
            <div className="row">
              <div className={ leftGridSize + ' wide column turkish property-details-left' }>
                <h1>{ data.title }</h1>
                <h2>{ headline }</h2>
                <ul className="cf">
                  { listItems.map((item) => item) }
                </ul>
                <p className="text-center">
                  { property.slug === 'hafnarbraut9' &&
                    <React.Fragment>
                      <Link to={ '/' + this.property + '/skilalysing' }>Skoða skilalýsingu</Link><br />
                    </React.Fragment>
                  }
                  { kjallariLink && (
                    <>
                      <a href={ kjallariLink } target="_blank">
                        { kjallariText } 
                      </a>
                      <br/><br/>
                    </>
                  )}
                  { loanLink && <a href={ loanLink } target="_blank">Reikna lán</a> }
                </p>
                <p className="property-details-small-p">
                  { paragraph }
                </p>
              </div>
              <div className={ rightGridSize + ' wide column property-details-right'}>
                <div className="vertical-align">
                  { directionIcon }
                  
                  <a href={ image.src } target="_blank" rel="noopener noreferrer">
                    <img src={ image.src } sizes="100vw" srcSet={ image.srcSet } />
                  </a>
                  <p style={{ display: 'block', color: '#444', paddingBottom: '25px' }}>
                    Smellið á grunnmynd fyrir stærri útgáfu
                  </p>
                  { imageTwo && (
                    <>
                      <a href={ imageTwo.src } rel="noopener noreferrer" target="_blank">
                        <img src={ imageTwo.src } sizes="100vw" srcSet={ imageTwo.srcSet } />
                      </a>
                      <p style={{ display: 'block', color: '#444', paddingBottom: '25px' }}>
                        Smellið á grunnmynd fyrir stærri útgáfu
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SingleProperty.propTypes = {
  fetchApartments: PropTypes.func.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  apartments: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  currentFloor: PropTypes.number.isRequired,
  setCurrentFloor: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  hover: PropTypes.string.isRequired,
  popup: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired,
  changeVector: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  apartments: state.property.items,
  properties: state.property.list,
  currentFloor: state.property.currentFloor,
  hover: state.property.hover,
  popup: state.property.popup,
  currentVector: state.property.currentVector
});

export default connect(mapStateToProps, {
  fetchApartments,
  fetchProperties,
  setCurrentFloor,
  onHover,
  openPopup
})(SingleProperty);
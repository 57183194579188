import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchApartments, setCurrentFloor } from 'actions/propertyActions';
import ListItem from './ListItem.js';

import './List.scss';
import 'react-dropdown/style.css';

class PropertyList extends Component {
  componentWillMount() {
    this.props.fetchApartments();
  }

  onDropdownChange = (selectedOption) => {
    this.props.setCurrentFloor(selectedOption.value);
  }

  render() {
    if(this.props.apartmentList.length === 0) {
      return (
        <p>Loading...</p>
      );
    }

    const dropdownOptions = this.props.dropdownFloorOptions[this.props.property];
    const apartmentList = this.props.apartmentList;
    const currentFloor = this.props.currentFloor;
    const visibleVector = this.props.visibleVector;

    const apartments = Object.keys(apartmentList)
                             .filter(property => apartmentList[property].floor == currentFloor  && apartmentList[property].hus === this.props.property)
                             .map(item => {
                              return this.props.apartmentList[item];
                             });

    var currentValue = this.props.currentFloor + '. hæð';

    if(this.props.property === 'hafnarbraut11' && currentFloor === 0) {
      currentValue = 'Vinnustofur';
    }
    
    return (
      <React.Fragment>
        <Dropdown options={ dropdownOptions }
                  onChange={ this.onDropdownChange }
                  value={ currentValue }
                  placeholder="Veldu hæð" />

        <div className="property-list-ul-wrap cf">
          <ul className={`property-list-ul` + ( apartments.length > 8 ? ' property-list-ul-lrg' : '' ) }>
            { apartments.map((apt) =>
              <ListItem key={ apt.id }
                          itemKey={ apt.ibudarnumer }
                          title={ apt.title }
                          className={ ( apt.sold === "1" ? 'apt-sold' : ( apt.on_sale === "1" ? 'apt-avail' : 'apt-later' ) ) }
                          side={ apt.hlid }
                          property={ apt.hus }
                          actionType='link'
                          link={ apt.hus + '/' + apt.ibudarnumer }
                />
              )
            }
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

PropertyList.propTypes = {
  fetchApartments: PropTypes.func.isRequired,
  apartmentList: PropTypes.object.isRequired,
  apartmentListByFloor: PropTypes.object.isRequired,
  currentFloor: PropTypes.number.isRequired,
  setCurrentFloor: PropTypes.func.isRequired,
  dropdownFloorOptions: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  apartmentList: state.property.items,
  apartmentListByFloor: state.property.itemsByFloor,
  currentFloor: state.property.currentFloor,
  dropdownFloorOptions: state.property.dropdownFloorOptions
});

export default connect(mapStateToProps, {
  fetchApartments,
  setCurrentFloor
})(PropertyList);
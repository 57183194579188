import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class MyHashLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var location = this.props.location.pathname;
    var pieces = location.split('/');
    var hash = this.props.hash;
    var originalPath = location.replace(/\/$/, '');
    var href = originalPath + '/' + hash;

    if(pieces[2]) {
      href = '/' + pieces[1] + '/' + hash;
    }

    if(pieces[1] === 'ibudir') {
      href = '/hafnarbraut9/' + hash;
    }

    return (
      <Link to={href} onClick={this.props.onClick} className={this.props.className} style={this.props.style}>{this.props.children}</Link>
    );
  }
}

export default withRouter(MyHashLink);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { onHover, openPopup, closePopup } from 'actions/propertyActions';

class ListItem extends Component {
  render() {
    const { itemKey, link, property, title, side, className, onHover, openPopup, closePopup, actionType } = this.props;

    var actionElem = <button
        onClick={ openPopup }
        data-side={ side }
        data-key={ itemKey }
        onMouseEnter={ actionType === 'openPopup' ? openPopup : onHover }
        onMouseLeave={ actionType ==='openPopup' ? closePopup : onHover }
        className={ className }>
      { title }
      <span className="property-list-underline">&nbsp;</span>
    </button>;

    if(actionType === 'link') {
      // const href = '/' + property + '/' + itemKey;

      actionElem = <Link to={ '/' + link } className={ className } onClick={ closePopup } data-side={ side } data-key={ itemKey } onMouseEnter={ openPopup } onMouseLeave={ closePopup } >
        { title }
        <span className="property-list-underline">&nbsp;</span>
      </Link>;
    }

    return (
      <li key={ 'property-list-item-' + itemKey }>
        { actionElem }
      </li>
    );
  }
}

ListItem.propTypes = {
  openPopup: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  popup: state.property.popup,
});

export default connect(mapStateToProps, {
  onHover,
  openPopup,
  closePopup
})(ListItem);
import React from "react";

const SvgNorth = props => (
  <svg viewBox="0 0 514.01 743.33" {...props}>
    <path
      d="M257.49.06c.93-.31.82.58 1 1q5.09 10.53 10.14 21.13 70.8 149.65 141 299.61c27.77 59.21 55.68 118.36 82.84 177.85 6.57 14.42 13.22 28.81 18.88 43.62 1 2.61 1.81 5.29 2.7 7.94v3.1a21.69 21.69 0 0 1-5.54 3.1 2.86 2.86 0 0 1-3.41-.69c-4.82-5-9.74-9.81-14.59-14.73-3-3.07-6-6.19-9-9.31q-7-7.27-14-14.56-6.66-7-13.29-13.94l-11.24-11.82-12.18-12.84-10.14-10.73q-5.71-6-11.42-12.07l-9.81-10.4-10.55-11.17-9.9-10.51-10.55-11.19-9.91-10.5q-5.7-6-11.42-12.07-5.28-5.58-10.57-11.16-6.19-6.54-12.41-13.05-6-6.36-12.09-12.71c-4.76-5-9.58-9.88-14.26-14.93-2.51-2.72-5.16-5.29-7.72-8-3.09-3.24-6.26-6.39-9.3-9.67-5.26-5.66-10.86-11-16.23-16.54-2.46-2.54-5.07-4.93-7.54-7.44-.82-.83-1.36-.78-2.13 0-6.64 7-13.32 13.93-20 20.91q-8.43 8.84-16.81 17.72-11 11.63-22 23.24c-4.49 4.76-8.93 9.55-13.42 14.31-5.91 6.26-11.85 12.48-17.77 18.73q-7.48 7.92-14.93 15.87l-25.66 27.2-15.26 16.2-21 22.24q-8.07 8.54-16.14 17.07c-7.62 8-15.27 16-22.86 24.12-4 4.28-8.08 8.52-12.14 12.77-7.58 7.94-15.11 15.93-22.81 23.76-2 2.05-4 4.15-6 6.2a2.39 2.39 0 0 1-1.54 0 30.13 30.13 0 0 1-4.59-2.83 4.37 4.37 0 0 1-1.77-4.87 85.15 85.15 0 0 1 5.31-15.29c10.35-24.62 21.68-48.81 33-73 64.95-138.81 130-277.56 196.4-415.69 7.4-15.4 14.78-30.8 22.63-45.98z"
      fill="#367384"
    />
    <path
      d="M211.58 547.52l17.15 32.47q10.16 19.18 20.34 38.37l16.24 30.7q10.92 20.61 21.87 41.19 5.13 9.71 10.27 19.4c.15.27.16.72.91.86V547.52c.25-.26.57-.2.88-.2h23.93a1.28 1.28 0 0 1 .89.17 2.46 2.46 0 0 1 .17 1.51v192.78c0 .5.16 1-.18 1.5h-34.7a1.59 1.59 0 0 1-1.69-1.1c-1.45-2.91-3.1-5.71-4.62-8.58-1.62-3.06-3.15-6.15-4.76-9.21q-4-7.65-8.05-15.27l-8.37-15.78q-4-7.56-8-15.11-4.2-7.89-8.37-15.78l-8-15.11-8.36-15.78q-4-7.64-8.08-15.26c-2.83-5.35-5.63-10.72-8.46-16.07-2.64-5-5.31-10-8-15a40.28 40.28 0 0 0-3-5v161c0 2 0 2-2 2h-23.61a3.55 3.55 0 0 1-.14-1.68V549.17c0-2.15-.22-1.9 1.89-1.9h32.22c.55.08 1.13-.1 1.63.25z"
      fill="#367384"
    />
    <path
      d="M28.79 515.52l20.19-43c62.25-132.4 124.12-265 186.77-397.19 6.22-13.12 12.54-26.18 18.82-39.27.3-.64.64-1.27 1.12-2.2a14.55 14.55 0 0 1 .52 3.51c.59 11.65.78 23.32.94 35 .67 49.72.49 99.44.52 149.17v49.57a5.38 5.38 0 0 1-1.63 4.07q-77.59 82.41-155.16 164.89c-23.61 25.07-47.11 50.25-71 75.09-.25.26-.52.49-.78.74-.26.07-.42-.01-.31-.38z"
      fill="#fff"
    />
  </svg>
);

export default SvgNorth;


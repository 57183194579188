import React from "react";

const SvgSouth = props => (
  <svg viewBox="0 0 557.85 721.41" {...props}>
    <path
      d="M557.79,257.52c.31.93-.58.82-1,1q-10.53,5.09-21.13,10.14-149.65,70.8-299.61,141C176.84,437.43,117.69,465.34,58.2,492.5c-14.42,6.57-28.81,13.22-43.62,18.88-2.61,1-5.29,1.81-7.94,2.7H3.54a21.56,21.56,0,0,1-3.1-5.54,2.85,2.85,0,0,1,.69-3.41c5-4.82,9.81-9.74,14.73-14.59,3.07-3,6.19-6,9.31-9q7.26-7,14.56-14l13.94-13.29L65.49,443l12.84-12.18,10.73-10.14q6-5.71,12.07-11.42l10.4-9.81,11.17-10.55,10.51-9.9,11.19-10.55,10.5-9.91q6-5.7,12.07-11.42l11.16-10.57q6.54-6.19,13.05-12.41,6.36-6,12.71-12.09c5-4.76,9.88-9.58,14.93-14.26,2.72-2.51,5.29-5.16,8-7.72,3.24-3.09,6.39-6.26,9.67-9.3,5.66-5.26,11-10.86,16.54-16.23,2.54-2.46,4.93-5.07,7.44-7.54.83-.82.78-1.36,0-2.13-7-6.64-13.93-13.32-20.91-20q-8.85-8.43-17.72-16.81l-23.24-22c-4.76-4.49-9.55-8.93-14.31-13.42-6.26-5.91-12.48-11.85-18.73-17.77q-7.92-7.49-15.87-14.93l-27.2-25.66L106.29,109,84.05,88,67,71.89C59,64.27,51,56.62,42.86,49,38.58,45,34.34,41,30.09,36.89,22.15,29.31,14.16,21.78,6.33,14.08c-2-2-4.15-4-6.2-6a2.39,2.39,0,0,1,0-1.54A29.65,29.65,0,0,1,3,2,4.37,4.37,0,0,1,7.83.18,85.42,85.42,0,0,1,23.12,5.49c24.62,10.35,48.81,21.68,73,33,138.81,64.95,277.56,130,415.69,196.4C527.21,242.29,542.61,249.67,557.79,257.52Z"
      fill="#367384"
    />
    <path
      d="M233.49,525.64l17.15,32.47q10.17,19.17,20.34,38.37l16.24,30.7q10.92,20.61,21.87,41.19l10.27,19.4c.15.27.16.72.91.86v-163c.25-.26.57-.2.88-.2h23.93a1.21,1.21,0,0,1,.89.17h0a2.38,2.38,0,0,1,.17,1.51V719.9c0,.5.16,1-.18,1.5h-34.7a1.6,1.6,0,0,1-1.69-1.1c-1.45-2.91-3.1-5.71-4.62-8.58-1.62-3.06-3.15-6.15-4.76-9.21q-4-7.65-8.05-15.27l-8.37-15.78q-4-7.56-8-15.11-4.2-7.89-8.37-15.78l-8-15.11L251,609.68q-4-7.65-8.08-15.26c-2.83-5.35-5.63-10.72-8.46-16.07q-4-7.5-8-15a38.93,38.93,0,0,0-3-5v161c0,2,0,2-2,2H197.89a3.68,3.68,0,0,1-.14-1.68V527.29c0-2.15-.22-1.9,1.89-1.9h32.22C232.41,525.47,233,525.29,233.49,525.64Z"
      fill="#367384"
    />

    <path
      d="M42.33,28.82,85.33,49c132.4,62.25,265,124.12,397.19,186.77,13.12,6.22,26.18,12.54,39.27,18.82.64.3,1.27.64,2.2,1.12a15,15,0,0,1-3.51.52c-11.65.59-23.32.78-35,.94-49.72.67-99.44.49-149.17.52H286.74a5.39,5.39,0,0,1-4.07-1.63q-82.41-77.59-164.89-155.16C92.71,77.3,67.53,53.8,42.69,29.91c-.26-.25-.49-.52-.74-.78C41.88,28.87,42,28.71,42.33,28.82Z"
      fill="#fff"
    />

  </svg>
);

export default SvgSouth;


import React from "react";

const SvgExpand = props => (
  <svg viewBox="0 0 51.84 51.84" {...props}>
    <path
      d="M0 17.13V2.78a2.69 2.69 0 0 1 .81-2 2.69 2.69 0 0 1 2-.81h14.32a1.34 1.34 0 0 1 1 .41 1.33 1.33 0 0 1 .41 1V6a1.4 1.4 0 0 1-1.39 1.39H7.41v9.72A1.4 1.4 0 0 1 6 18.52H1.39a1.33 1.33 0 0 1-1-.41 1.34 1.34 0 0 1-.39-.98zm18.52 33.33v-4.63a1.35 1.35 0 0 0-.41-1 1.34 1.34 0 0 0-1-.4h-9.7v-9.71a1.35 1.35 0 0 0-.41-1 1.34 1.34 0 0 0-1-.4H1.39a1.32 1.32 0 0 0-1 .4 1.35 1.35 0 0 0-.41 1v14.35a2.66 2.66 0 0 0 .81 2 2.69 2.69 0 0 0 2 .81h14.34a1.38 1.38 0 0 0 1.39-1.38zM33.33 1.39V6a1.34 1.34 0 0 0 .4 1 1.35 1.35 0 0 0 1 .41h9.72v9.72a1.34 1.34 0 0 0 .4 1 1.35 1.35 0 0 0 1 .41h4.63a1.38 1.38 0 0 0 1.38-1.39V2.78a2.69 2.69 0 0 0-.81-2 2.66 2.66 0 0 0-2-.81H34.72a1.35 1.35 0 0 0-1 .41 1.32 1.32 0 0 0-.39 1.01zm17.13 31.94h-4.63a1.37 1.37 0 0 0-1.39 1.39v9.72h-9.72a1.37 1.37 0 0 0-1.39 1.39v4.63a1.37 1.37 0 0 0 1.39 1.38h14.35a2.77 2.77 0 0 0 2.77-2.77V34.72a1.37 1.37 0 0 0-1.38-1.39z"
      fill="#231f20"
    />
  </svg>
);

export default SvgExpand;


import React, { Component } from 'react';
import classNames from 'classnames';
import { AvailableAnchor, SoldAnchor, LaterAnchor, HoldAnchor, Text, SvgWrap } from './styles';

class FloorMap extends Component {
  state = {
    activeApartment: null
  };

  handleHover = (evt) => {
    this.props.openPopup(evt);
  }

  render() {
    const apartmentData = this.props.apartmentData;

    if(apartmentData.length === 0) return null;
    let slug = this.props.data.slug || '/ibudir/';
    
    const apartmentPaths = this.props.data.apartments;
    const currentFloor = parseInt(this.props.activeFloor || this.props.selectedFloor || 1, 10);
    const current = apartmentPaths.filter(apartment => apartment.floor.indexOf(currentFloor) !== -1);
    const activeApartment = this.props.activeApartment || this.props.selectedApartment;
    const currentProperty = this.props.currentProperty;
    const viewBox = this.props.data.viewBox;

    return (
      <SvgWrap>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={ viewBox }>
          {
            current.map(apartment => {
              const currentApartment = apartment.ibud.replace('x', currentFloor);
              const linkClassNames = classNames({
                'active': activeApartment === currentApartment
              })

              const hoverProps = Object.assign({}, apartment, { 'floor': currentFloor })
              const currentApartmentData = apartmentData.filter(
                apt => apt.ibudarnumer === currentApartment && apt.hus === currentProperty
              )[0];

              const sharedProps = {
                "data-key": apartment.ibud,
                "data-side": currentApartmentData && currentApartmentData.hlid ? currentApartmentData.hlid : 'framhlid',
              }

              const linkProps = {
                ...sharedProps,
                className: linkClassNames,
                onMouseEnter: this.handleHover,
                onMouseLeave: this.handleHover,
                key: apartment.ibud,
                to: slug + currentApartment
              }

              var paths = apartment.paths ? apartment.paths : null;
              
              if(!paths) {
                paths = [ { path: apartment.path } ];
              }

              const Links = {
                0: AvailableAnchor,
                1: SoldAnchor,
                2: HoldAnchor
              }

              let Link = currentApartmentData ? Links[currentApartmentData.sold] : AvailableAnchor;

              if(currentApartmentData && currentApartmentData.on_sale === '0') {
                Link = LaterAnchor;
              }

              return (
                <Link {...linkProps}>
                  {
                    paths.map( (pathObj, idx) => {
                      var propsToPass = {
                        ...sharedProps,
                        'd': pathObj.path,
                        'transform': pathObj.transform
                      }

                      if(pathObj.hasOwnProperty('type')) {
                        propsToPass['data-type'] = pathObj.type;
                      }

                      return (
                        <path 
                          key={ 'floor-map-path-' + apartment.ibud + idx }
                          { ...propsToPass } 
                        />
                      )
                    })
                  }

                  { apartment.textTransform &&
                    <Text transform={ apartment.textTransform } dominantBaseline="middle">
                      { currentApartmentData.title_short }
                    </Text>
                  }
                </Link>
              )
            }
            )
          }
        </svg>
      </SvgWrap>
    );
  }
}

export default FloorMap;
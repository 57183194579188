import { combineReducers } from 'redux';
import propertyReducer from './propertyReducer';
import splashReducer from './splashReducer';
import navReducer from './navigationReducer';
import { responsiveStateReducer } from 'redux-responsive';

export default combineReducers({
  property: propertyReducer,
  splash: splashReducer,
  browser: responsiveStateReducer,
  navigation: navReducer
});
import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import './Newsletter.scss';

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribed: false,
      message: '',
      slod: '',
      netfang: ''
    };

    this.subscribe = this.subscribe.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange(e) {
    var state = {};
    var inputs = ['netfang', 'slod'];

    if(e.target.name === 'netfang' || e.target.name === 'slod') {
      state[e.target.name] = e.target.value;
    }

    this.setState(state);
  }

  subscribe(e) {
    e.preventDefault();

    var state = this.state;

    fetch('https://karsnes.is/api/postlisti/sub.php', {
      method: 'POST',
      body: JSON.stringify({
        slod: this.state.slod,
        email: this.state.netfang,
        subscribe: 1
      })
    })
    .then(response => {
      response.json().then(data =>{
        this.setState({
          subscribed: 1,
          message: data.message
        })
      });
    });

    ReactPixel.trackCustom('newsletterSubscribe', {});
  }

  render() {
    var inputs = '';

    if(!this.state.subscribed) {
      inputs = (
        <form className="newsletter-inputs cf" onSubmit={ this.subscribe }>
          <input type="text" name="slod" className="input-slod" value="" onChange={ this.handleChange } />
          <input type="email" name="netfang" placeholder="Vinsamlega settu inn netfang" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" 
          title="Vinsamlegast stimplaðu inn gilt netfang." required onChange={ this.handleChange } />
          <input type="submit" name="" value="Skrá mig" />
        </form>
      );
    }

    return (
      <div className={ this.state.subscribed ? 'newsletter-subscribed newsletter cf' : 'newsletter cf' }>
        { inputs }
        <div className="newsletter-msg">
          { this.state.message }
        </div>
      </div>
    );
  }
}

export default Newsletter;
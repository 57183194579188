import {
  SET_NAV_STATE
} from 'actions/types';

const initialState = {
  isOpen: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_NAV_STATE:
      return Object.assign({}, state, {
        isOpen: action.payload
      });
    default:
      return state;
  }
}